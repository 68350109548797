import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundPosition: 0,
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { logo, background } = this.props;
    const styles = {
      background: '#2D3233',
      backgroundImage: `url(${background})`,
      backgroundPosition: `${this.state.backgroundPosition}% center`,
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'repeat-x',
      position: 'absolute',
      top: '1px',
      right: '1px',
      left: '1px',
      bottom: '1px',
    }

    return (
      <div className='logo'>
        <div className='logo-bg' style={styles}/>
        <div className='logo-img'><img src={logo} /></div>
      </div>
    )
  }

  handleScroll(event) {
    const scrollTop = window.pageYOffset;
    const speed = 10000; // cada 1000px 1 volta sencera
    const pos = parseInt(scrollTop / speed * 100);

    this.setState({
      backgroundPosition: pos
    });
  }
}

Logo.propTypes = {
  logo: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};
export default Logo
