import $ from 'jquery'
import Swiper from 'swiper'

const isScrolledIntoView = (elem) => {
  let scroll = $(window).scrollTop();
  let elemBottom = $(elem).scrollTop() + ($(elem).height() / 2);
  return (scroll <= elemBottom);
}

const onReady = () => {
  // let $scrollable = $('.project-scrollable')
  // let $projectCover = $('.project-cover')
  // if($scrollable.length && $projectCover.length) {
  //   const initialScrollHeight = ($(window).height() / 2);
  //   const scrollHeight = initialScrollHeight - $(window).scrollTop() / 2;
  //
  //   (scrollHeight <= 0) ? $('header').addClass('sticky') : $('header').removeClass('sticky');
  //   $scrollable.css({
  //     transform: `matrix(1, 0, 0, 1, 0, -${scrollHeight})`,
  //   });
  //
  //   $(document).scroll(() => {
  //     // per cada 2 px de scroll, 1px de height
  //     const scrollHeight = initialScrollHeight - $(window).scrollTop() / 2;
  //     // console.log("scrollHeight", scrollHeight);
  //     if (scrollHeight <= 0) {
  //       $('header').addClass('sticky')
  //       $scrollable.css({ transform: 'none' });
  //       $scrollable.addClass('in');
  //     } else {
  //       $('header').removeClass('sticky');
  //       $scrollable.css({
  //         transform: `matrix(1, 0, 0, 1, 0, -${scrollHeight})`,
  //       })
  //       $scrollable.removeClass('in');
  //     }
  //   })
  // }

  $('.project-cover-video-controls').on('click', (e) => {
    const $cover = $(e.target).parents('.project-cover-video')
    const $button = $cover.find('.project-cover-video-controls')
    const $video = $cover.find('video')
    const video = $video.get(0)
    $button.fadeOut(100);
    $video.get(0).play();
    $video.one('ended', () => {
      $button.fadeIn(100);
    })
  });

  const pauseVideo = () => {
    const $cover = $('.project-cover-video')
    const $button = $cover.find('.project-cover-video-controls')
    const $video = $cover.find('video')
    const video = $video.get(0)
    $button.fadeIn(100);
    $video.get(0).pause();
  }

  $('.hamburger, nav li a').click(() => {
    $('.overlay').fadeToggle(250);
    $('.hamburger').toggleClass('is-active');
    $('.header-menu').toggleClass('in');
  });

  $(".studio-events-link").click((e) => {
    e.preventDefault();
    e.stopPropagation();
    const headerHeight = $('header').outerHeight() + 23;

    $([document.documentElement, document.body]).animate({
        scrollTop: $(e.target.getAttribute('href')).offset().top - headerHeight
    }, 700);
  });

  // $('.project-block-image').each((i, e) => {
  //   console.log("PARSING!")
  //   const aspectRatio = $(e).find('img').width() / $(e).find('img').height();
  //   $(e).css({ flex: `${aspectRatio}` });
  // })

  $("#studio-sticky").sticky({ topSpacing: 120 });

  $('.project-list-slice-more').click((e) => {
    const $studioEventCategory = $(e.target).parents('.studio-event-category');
    const el = $studioEventCategory.find('.project-list-slice:hidden')[0];
    $(el).slideDown().addClass('is-visible');
    if($studioEventCategory.find('.project-list-slice:hidden').length <= 0) $(e.target).hide()
  })

  const initialHeaderHeight = $('header').outerHeight();

  $(document).on("scroll", function(){
    if ($(document).scrollTop() > initialHeaderHeight) {
      $('header').addClass('shrink');
    } else {
      $('header').removeClass('shrink');
    }
  });

  $(document).on("scroll", function() {
    if($('.project-cover video').length) {
      const $projectCover = $('.project-cover');
      const $coverVideo = $('.project-cover video');
      const video = $coverVideo.get(0);
      if ($coverVideo.length && !isScrolledIntoView($projectCover.get(0))) {
        pauseVideo();
      } else {
        // video.play();
      }
    }
  });

  // Slider (swiper)
  //initialize swiper when document ready
  var swiper = new Swiper ('.swiper-container', {
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: false,
    // autoplay: {
    //   delay: 3000,
    // },
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 2,
      }
    },
    speed: 500,
    spaceBetween: 20,
    // on: {
    //   init: function () {
    //     this.autoplay.stop();
    //   },
    // }
  });

  // Open all external links in new window
  // Open all external links in a new window
  addEventListener("click", function(event) {
    var el = event.target

    if (el.tagName === "A" && !el.isContentEditable && el.host !== window.location.host) {
      el.setAttribute("target", "_blank")
    }
  }, true)

  $('.arrow-input img').on('click', (e) => {
    $(e.target).parents('form').submit();
  });
};

$(document).ready(onReady);
// $(document).on('turbolinks:load', onReady);
// $(document).on('turbolinks:load', () => {
//   console.log("turbolinks:load!")
//   onReady();
// });
