import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags || [],
    }
    this.updateUrl = this.updateUrl.bind(this);
  }

  render() {
    const { projects } = this.props;

    return (
      <div className='projects-list'>
        <div className='row'>
          <div className='col-10 offset-2 col-md-7 offset-md-5 tag-list'>
            { this.state.tags.map(tag => this.renderTagListLink(tag)) }
          </div>
        </div>
        <div>
          { this.props.projects.map((project, index) => this.renderProject(project, index)) }
        </div>
      </div>
    )
  }

  renderProject(project, index) {
    const tagsIntersection = this.state.tags.filter(tag => project.tags.includes(tag));
    if (this.state.tags.length == 0 || tagsIntersection.length == this.state.tags.length) {
      return (
        <div key={`project-${index}`}className='projects-list-item' onMouseOver={this.handleProjectOnMouseOver} onMouseOut={this.handleProjectOnMouseOut}>
          <div className='row'>
            <div className='col-2 col-md-1 projects-list-item-cell projects-list-item-cell-content-top'>{ project.year }</div>
            <div className='col-10 col-md-4 projects-list-item-cell projects-list-item-cell-content-top'><a href={project.url} className='underline-link'>{ project.title }</a></div>
            <div className='col-10 offset-2 col-md-7 offset-md-0'>
              <div className='projects-list-item-cell projects-list-item-cell-content-bottom'>
                { project.tags.map(tag => this.renderTagLink(project.tags, tag)) }
              </div>
              <div className='projects-list-item-image'>
                <img src={ project.imageUrl } />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  renderTagLink(tags, tag) {
    const notLast = (tags.indexOf(tag) < tags.length - 1);

    return (
      <span className='projects-list-item-tag' key={tag} onClick={() => { this.selectTag(tag) }}>
        <span className='underline-link'>{tag}</span>
        {notLast && ', '}
      </span>
    )
  }

  renderTagListLink(tag){
    return (
      <span className='tag-list-item' key={tag}>
        <span className='tag-list-item-name underline-link'>{tag}</span>
        <span className='tag-list-item-close' onClick={() => { this.removeTag(tag) }}><img src={this.props.closeImage} /></span>
      </span>
    )
  }

  selectTag(tag) {
    if (this.state.tags.indexOf(tag) == -1) {
      this.setState({
        tags: [...this.state.tags, tag]
      }, this.updateUrl)
    }
  }

  removeTag(tag) {
    const index = this.state.tags.indexOf(tag);
    if (index >= 0) {
      this.setState({
        tags: [...this.state.tags.slice(0, index), ...this.state.tags.slice(index + 1)]
      }, this.updateUrl)
    }
  }

  updateUrl() {
    const path = this.state.tags.length ? `?tags=${this.state.tags.join(',')}` : null;
    window.history.pushState({},"", path);
  }

  handleProjectOnMouseOver(e) {
    const $parent = $(e.target).parents('.projects-list-item');
    const $image = $parent.find('.projects-list-item-image img');
    const imageHeight = $image.height();
    const remainingSpaceTop = $parent.offset().top - $(window).scrollTop() - $('header').outerHeight();
    const remainingSpaceBottom = window.innerHeight - remainingSpaceTop - $parent.outerHeight();
    if(remainingSpaceTop > remainingSpaceBottom) $parent.addClass('project-list-item-image-top');
  }

  handleProjectOnMouseOut(e) {
    const $parent = $(e.target).parents('.projects-list-item').removeClass('project-list-item-image-top');
  }
}

ProjectList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  projects: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  closeImage: PropTypes.string.isRequired,
};
export default ProjectList
